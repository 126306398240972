import Trix from "trix";

// Modified version of Trix Editor's (1.2.2) toolbar configuration file `trix/src/trix/config/toolbar.coffee`.
// Unused facilities are removed from the editor's toolbar:
// - File attachments (not a feature that this project uses)
// - Code block formatting (IE11 locks up when editting a code block)
// eslint-disable-next-line i18next/no-literal-string
export const defaultTrixToolbarHTML = (lang) => `
<div class="trix-button-row">
  <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
    <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${lang.strike}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
  </span>

  <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
    <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="${lang.heading1}" tabindex="-1">${lang.heading1}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${lang.quote}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${lang.outdent}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${lang.indent}</button>
  </span>

  <span class="trix-button-group-spacer"></span>

  <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
    <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${lang.undo}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${lang.redo}</button>
  </span>
</div>

<div class="trix-dialogs" data-trix-dialogs>
  <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
    <div class="trix-dialog__link-fields">
      <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>
      <div class="trix-button-group">
        <input type="button" class="trix-button trix-button--dialog" value="${lang.link}" data-trix-method="setAttribute">
        <input type="button" class="trix-button trix-button--dialog" value="${lang.unlink}" data-trix-method="removeAttribute">
      </div>
    </div>
  </div>
</div>
`;

document.addEventListener("DOMContentLoaded", () => {
  Trix.config.toolbar.getDefaultHTML = () => defaultTrixToolbarHTML(Trix.config.lang);
  // Trix Editor's default implementation of strikethrough is missing a facility for parsing such text.
  // So, do a simple search for any strikethrough tags and mark those as strikethrough.
  // NOTE: `Trix` is returned on module import, which is done in another file, and thus exists despite warnings.
  Trix.config.textAttributes.strike.parser = (element) =>
    element.tagName.toLowerCase() === Trix.config.textAttributes.strike.tagName;

  // Replaces all elements that contain the default content editor with the desired content editor.
  const richTextElements = document.querySelectorAll("textarea.rich_text");
  for (const richTextElement of Array.from(richTextElements)) {
    // Retrieve the values that we want to transfer from the original content editor.
    const textareaID = richTextElement.getAttribute("id");
    const textareaName = richTextElement.getAttribute("name");
    const textareaContent = richTextElement.textContent;

    // Create and configure the replacement CMS editor (Trix Editor).
    // Trix Editor requires a hidden input to link the editor content with the form data.
    const trixInputElement = document.createElement("input");
    trixInputElement.setAttribute("id", textareaID);
    trixInputElement.setAttribute("type", "hidden");
    trixInputElement.setAttribute("class", "content");
    trixInputElement.setAttribute("name", textareaName);
    // Load the contents from the original editor into the Trix document.
    trixInputElement.setAttribute("value", textareaContent);
    // Trix Editor requires the WYSIWYG interface for entering and managing CMS content.
    const trixEditorElement = document.createElement("trix-editor");
    trixEditorElement.setAttribute("input", textareaID);
    trixEditorElement.classList.add("trix-content");

    // Overwrite the original content editor with the Trix editor.
    const { parentNode } = richTextElement;
    if (parentNode != null) {
      parentNode.appendChild(trixInputElement);
      parentNode.appendChild(trixEditorElement);
      parentNode.removeChild(richTextElement);
    }
  }

  // Prevent file attachments from being accepted by Trix Editor (that is its default behaviour).
  document.addEventListener("trix-file-accept", (event) => {
    return event.preventDefault();
  });
});
